import Utils from '../../services/Utils'
import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'

const AuditLogApi = {
  getFilters: async function (cancel = false) {
    const response = await GApi.request({
      url: '/core/audit-log-filters',
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  getList: async function (module, entity, event, cancel = false) {
    module = module === -1 ? '' : module
    entity = entity === -1 ? '' : entity
    event = event === -1 ? '' : event

    const response = await GApi.request({
      url:
        '/core/audit-log?module=' +
        module +
        '&entity=' +
        entity +
        '&event=' +
        event,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for AuditLogApi
const cancelApiObject = defineCancelApiObject(AuditLogApi)
export default AuditLogApi
