import Utils from '../../services/Utils'
import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'

const UserReportApi = {
  getList: async function (userId, cancel = false) {
    const response = await GApi.request({
      url: '/global-reporting/user-report/' + userId,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.getList.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(UserReportApi)
export default UserReportApi
