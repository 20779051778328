import Download from '../../services/Download'
import Utils from '../../services/Utils'
import { FormErrorStrategy } from '../config/ErrorHandler'
import {
  GApi,
  defineCancelApiObject,
  defaultHeaders,
  csvDownloadHeaders,
} from '../config/GApi'

const ServiceUserApi = {
  getList: async function (cancel = false) {
    const response = await GApi.request({
      url: '/service-user',
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  exportAsCsv: async function (cancel = false) {
    const response = await GApi.request({
      url: '/service-user',
      method: 'GET',
      headers: csvDownloadHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Download.csv('service-users.csv', response.data)
  },
  get: async function (userId, cancel = false) {
    const response = await GApi.request({
      url: '/service-user/' + userId,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },

  create: async function (post, cancel = false) {
    const response = await GApi.request({
      url: '/service-user',
      method: 'POST',
      headers: defaultHeaders(),
      errorStrategy: FormErrorStrategy,
      data: post,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  update: async function (userId, post, cancel = false) {
    const response = await GApi.request({
      url: '/service-user/' + userId,
      method: 'PUT',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  deleteServiceUser: async function (userId, cancel = false) {
    const response = await GApi.request({
      url: '/service-user/' + userId,
      method: 'DELETE',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object
const cancelApiObject = defineCancelApiObject(ServiceUserApi)

export default ServiceUserApi
