import Utils from '../../services/Utils'
import { FormErrorStrategy } from '../config/ErrorHandler'
import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'

const ServiceUserAccessApi = {
  getList: async function (cancel = false) {
    const response = await GApi.request({
      url: '/service-user-access',
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  updateAccess: async function (serviceUserId, post, cancel = false) {
    const response = await GApi.request({
      url: '/service-user-access/' + serviceUserId,
      method: 'PUT',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object
const cancelApiObject = defineCancelApiObject(ServiceUserAccessApi)

export default ServiceUserAccessApi
