import Utils from '../../services/Utils'
import {
  GApi,
  defineCancelApiObject,
  defaultHeaders,
  csvDownloadHeaders,
} from '../config/GApi'
import Download from '../../services/Download'

const PublicApiAccessLogApi = {
  get: async function (serviceUserId, from, to, cancel = false) {
    const response = await GApi.request({
      url:
        '/public-api/access-log/' +
        serviceUserId +
        '?from=' +
        from +
        '&to=' +
        to,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  exportAsCsv: async function (serviceUserId, from, to, cancel = false) {
    const response = await GApi.request({
      url:
        '/public-api/access-log/' +
        serviceUserId +
        '?from=' +
        from +
        '&to=' +
        to,
      method: 'GET',
      headers: csvDownloadHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Download.csv('api-access-log.csv', response.data)
  },
}

// defining the cancel API object
const cancelApiObject = defineCancelApiObject(PublicApiAccessLogApi)

export default PublicApiAccessLogApi
