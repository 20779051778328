import Utils from '../../services/Utils'
import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'

const SystemInfoApi = {
  getList: async function (params, cancel = false) {
    const response = await GApi.request({
      url: '/core/system-info',
      method: 'GET',
      params: params,
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(SystemInfoApi)

export default SystemInfoApi
