class PublicRoutes {
  static all = [
    '/login',
    '/saml',
    '/forgot-password',
    '/update-password',
    '/logged-out',
  ]

  static isPublicRoute(route) {
    return this.all.includes(route)
  }

  static isCurrentPublic(route) {
    return this.all.includes(window.location.pathname)
  }
}

export default PublicRoutes
