import { UserAuthApi } from '../apis/index'
import { Cookies } from 'react-cookie'
import Utils from './Utils'

const cookies = new Cookies()

class Auth {
  static AUTH_MODE = process.env.REACT_APP_AUTH_MODE

  static isOktaAuth() {
    return Auth.AUTH_MODE === 'OKTA'
  }

  static getExpiresAt() {
    return cookies.get('jwtExpiresAt')
  }

  static getJwtToken() {
    const jwt = cookies.get('jwtToken')

    if (jwt === '') Auth.redirectToLogin(true, 'no-token')

    return jwt
  }

  static getName() {
    return cookies.get('name')
  }

  static getUserId() {
    return parseInt(cookies.get('jwtId'))
  }

  static getRole() {
    return cookies.get('role')
  }

  static isLoggedIn() {
    let loggedIn = cookies.get('jwtToken') !== undefined

    if (loggedIn) {
      const now = new Date()
      const expires = new Date(Auth.getExpiresAt())

      if (now > expires) {
        Auth.logout(false, 'no-token-on-start')
        loggedIn = false
      }
    }

    return loggedIn
  }

  static login(decodedToken) {
    const options = {
      path: '/',
      expires: new Date(decodedToken.expiresAt),
    }

    cookies.set('jwtToken', decodedToken.jwtToken, options)
    cookies.set('jwtExpiresAt', decodedToken.expiresAt, options)
    cookies.set('jwtId', decodedToken.jwtId, options)
    cookies.set('role', decodedToken.role, options)
    cookies.set('name', decodedToken.name, options)
  }

  static logout(redirect = true, reason = null) {
    UserAuthApi.logout()
      .then(() => {
        console.debug('Successfully logged out')
        Auth.clearSession()
        Auth.redirectToLogin(redirect, reason)
      })
      .catch((errorMsg) => {
        console.debug('Logout error: ' + errorMsg)
      })
  }

  static clearSession() {
    cookies.remove('jwtToken')
    cookies.remove('jwtExpiresAt')
    cookies.remove('jwtId')
    cookies.remove('role')
    cookies.remove('name')
  }

  static redirectToLogin(redirect = false, reason = null) {
    let url = '/login'

    if (redirect || reason) url = url + '?'
    if (redirect) url = url + 'redirect=' + window.location.pathname
    if (redirect && reason) url = url + '&'
    if (reason) url = url + 'reason=' + reason

    window.location.replace(url)
  }

  static decodeToken(responseData) {
    if (
      !responseData.hasOwnProperty('access_token') ||
      !responseData.hasOwnProperty('expires_at')
    ) {
      throw new Error('No authentication token returned')
    }

    const jwtToken = responseData.access_token
    const expiresAt = responseData.expires_at

    let decoded = Utils.decodeBase64(jwtToken.split('.')[1])
    decoded = JSON.parse(decoded)

    return {
      jwtToken: jwtToken,
      jwtId: decoded.uid,
      role: decoded.role,
      expiresAt: expiresAt,
      name: responseData.name,
    }
  }

  static initialUserState() {
    return {
      loggedIn: false,
      jwtToken: null,
      jwtExpiresAt: null,
      jwtId: null,
      role: null,
    }
  }
}

export default Auth
