import Utils from '../../services/Utils'
import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'
import { FormErrorStrategy } from '../config/ErrorHandler'

const ReportApi = {
  getList: async function (userId = null, cancel = false) {
    const params = userId === null ? '' : '?user=' + userId
    const response = await GApi.request({
      url: '/global-reporting/report' + params,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  get: async function (reportId, cancel = false) {
    const response = await GApi.request({
      url: '/global-reporting/report/' + reportId,
      method: 'GET',
      headers: defaultHeaders(),
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  create: async function (post, cancel = false) {
    const response = await GApi.request({
      url: 'global-reporting/report',
      method: 'POST',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  update: async function (reportId, post, cancel = false) {
    const response = await GApi.request({
      url: 'global-reporting/report/' + reportId,
      method: 'PUT',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  delete: async function (reportId, pointer, cancel = false) {
    const response = await GApi.request({
      url: 'global-reporting/report/' + reportId + '?pointer=' + pointer,
      method: 'DELETE',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  move: async function (pointer, direction, cancel = false) {
    const response = await GApi.request({
      url: 'global-reporting/move-report',
      method: 'POST',
      headers: defaultHeaders(),
      data: {
        pointer: pointer,
        direction: direction,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ReportApi)
export default ReportApi
