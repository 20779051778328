import { GApi, defineCancelApiObject, defaultHeaders, csvDownloadHeaders } from '../config/GApi'

// Services
import  Download  from '../../services/Download'
import Utils from '../../services/Utils'

const ReportAccessMatrixApi = {
  getList: async function (userId = null, cancel = false) {
    
    const response = await GApi.request({
      url: '/global-reporting/report-access-matrix',
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  exportAsCsv: async function (cancel = false) {
    const response = await GApi.request({
      url: '/global-reporting/report-access-matrix',
      method: 'GET',
      headers: csvDownloadHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Download.csv('report-access-matrix.csv', response.data)
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ReportAccessMatrixApi)
export default ReportAccessMatrixApi
