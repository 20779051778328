import Utils from '../../services/Utils'
import { FormErrorStrategy } from '../config/ErrorHandler'

import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'

const ScopeApi = {
  getList: async function (cancel = false) {
    const response = await GApi.request({
      url: 'auth/scope',
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  get: async function (scopeId, cancel = false) {
    const response = await GApi.request({
      url: 'auth/scope/' + scopeId,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  create: async function (post, cancel = false) {
    const response = await GApi.request({
      url: 'auth/scope',
      method: 'POST',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  update: async function (scopeId, post, cancel = false) {
    const response = await GApi.request({
      url: 'auth/scope/' + scopeId,
      method: 'PUT',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  delete: async function (scopeId, cancel = false) {
    const response = await GApi.request({
      url: 'auth/scope/' + scopeId,
      method: 'DELETE',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },

  search: async function (module, searchTerm, cancel = false) {
    const response = await GApi.request({
      url: 'auth/scope-search?module=' + module + '&term=' + searchTerm,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ScopeApi)
export default ScopeApi
