import Utils from '../../services/Utils'
import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'

const GRAccessGroupApi = {
  delete: async function (groupId, cancel = false) {
    const response = await GApi.request({
      url: '/global-reporting/access-group/' + groupId,
      method: 'DELETE',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for GRAccessGroupApi
const cancelApiObject = defineCancelApiObject(GRAccessGroupApi)
export default GRAccessGroupApi
