import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Navigate } from 'react-router'
import TagManager from 'react-gtm-module'
import { Toaster } from 'react-hot-toast'
import { withCookies } from 'react-cookie'

import './dpc-react-core/assets/FontAwesome'
import './assets/FontAwesome'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-sliding-side-panel/lib/index.css'
import './assets/scss/style.scss'

//Components
import SessionChecker from './dpc-react-core/components/SessionChecker'

//Services
import Utils from './dpc-react-core/services/Utils'
import Auth from './dpc-react-core/services/Auth'
import GA4 from './enums/GA4'
import PublicRoutes from './enums/PublicRoutes'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./dpc-react-core/views/core/auth/Login'))
const LoggedOut = React.lazy(() =>
  import('./dpc-react-core/views/core/auth/LoggedOut')
)
const Saml = React.lazy(() => import('./dpc-react-core/views/core/auth/Saml'))

//Google Analytics
if (Utils.thirdPartyEnabled()) {
  try {
    const tagManagerArgs = {
      gtmId: GA4.GTAGID,
    }

    TagManager.initialize(tagManagerArgs)
  } catch (err) {
    console.debug('Could not initialize Google Analytics')
  }
}

class App extends Component {
  CLIENT_ID = 'prosus-hris-app'

  constructor(props) {
    super(props)
    this.state = {
      userLoggedIn: Auth.isLoggedIn(),
      redirect: '',
    }

    this.setLoggedInState = this.setLoggedInState.bind(this)
  }

  setLoggedInState(redirect) {
    this.setState({ userLoggedIn: true, redirect: redirect })
  }

  render() {
    let { userLoggedIn, redirect } = this.state

    //If a user is logged in and tries to browse to login, update-password or forgot-password
    //then we log them out
    if (
      userLoggedIn &&
      ['/forgot-password', '/update-password', '/logged-out'].includes(
        window.location.pathname
      )
    ) {
      Auth.clearSession()
      userLoggedIn = false
    }

    //Show login page if user is not logged in
    if (!userLoggedIn) {
      const isLoginUrl = window.location.pathname === '/login'
      const redir = PublicRoutes.isCurrentPublic()
        ? ''
        : '?redirect=' + window.location.pathname

      if (!PublicRoutes.isCurrentPublic() && !isLoginUrl) {
        window.location.replace('/login' + redir)
      }

      return (
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Routes>
              <Route
                path="/saml"
                name="Saml"
                element={<Saml loggedIn={this.setLoggedInState} />}
              />

              <Route
                path="/login"
                element={
                  <Login
                    clientId={this.CLIENT_ID}
                    loggedIn={this.setLoggedInState}
                  />
                }
              />
              <Route
                exact
                path="/logged-out"
                name="LoggedOut Page"
                element={<LoggedOut />}
              />
            </Routes>
          </React.Suspense>
        </BrowserRouter>
      )
    }

    //Redirect after login
    if (redirect !== '' && redirect !== '/logged-out') {
      setTimeout(() => {
        this.setState({ redirect: '' })
      }, 10)

      return (
        <BrowserRouter>
          <Navigate to={redirect} />
        </BrowserRouter>
      )
    }

    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              element={
                <Login
                  clientId={this.CLIENT_ID}
                  loggedIn={this.setLoggedInState}
                />
              }
            />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
        <Toaster />
        <SessionChecker />
      </BrowserRouter>
    )
  }
}

export default withCookies(App)
