import React, { Component } from 'react'
import PublicRoutes from '../../enums/PublicRoutes'
import Auth from '../services/Auth'
import WithRouter from './WithRouter'

/**
 * Logs a user out from the se
 */
class SessionChecker extends Component {
  JWT_INTERVAL = 10000

  timerId

  constructor(props) {
    super(props)

    this.state = {
      seconds: this.props.seconds,
    }
    this.checkToken = this.checkToken.bind(this)
  }

  checkToken() {
    const isPublicRoute = PublicRoutes.isCurrentPublic()

    if (Auth.isLoggedIn() === false && !isPublicRoute) {
      clearInterval(this.timerId)
      window.location.replace('/login?reason=expired-token')
    }
  }

  componentDidMount() {
    this.timerId = setInterval(this.checkToken, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerId)
  }

  render() {
    const { seconds } = this.state
    return <span>{seconds}</span>
  }
}

export default WithRouter(SessionChecker)
