import { Buffer } from 'buffer'

class Utils {
  static capitalize(word) {
    word = word.replace('_', '')
    //eslint-disable-next-line
    return word.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())
  }

  static capitalizeWords(word) {
    word = word.replaceAll(/_/g, ' ')

    //eslint-disable-next-line
    return word.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase())
  }

  static cloneObject(objToClone) {
    return JSON.parse(JSON.stringify(objToClone))
  }

  static excludeObjProperties(obj, keysToExclude) {
    let newObj = {}

    for (const prop in obj) {
      if (!keysToExclude.includes(prop)) {
        newObj[prop] = obj[prop]
      }
    }

    return newObj
  }

  static findItemInArray(items, propertyName, valueToFind) {
    if (!items) {
      throw new Error(
        'Method findItemInArray requires items param to be an array. Name: ' +
          propertyName +
          ' Value: ' +
          valueToFind
      )
    }

    return items.find(function (item) {
      return item[propertyName] === valueToFind
    })
  }

  static findItemIndex(items, propertyName, valueToFind) {
    return items.findIndex(function (item) {
      return item[propertyName] === valueToFind
    })
  }

  static findIndex(items, valueToFind) {
    return items.findIndex(function (item) {
      return item === valueToFind
    })
  }

  static containsElement(parent, child) {
    if (!child || !child.parentElement) return false
    if (child.parentElement === parent) return true

    return Utils.containsElement(parent, child.parentElement)
  }

  static generateKey() {
    return parseInt(Date.now() / 1000)
      .toString()
      .substring(1)
  }

  static removeItemFromArray(list, valueToRemove) {
    if (!list) return []

    const index = list.indexOf(valueToRemove)
    if (index !== -1) {
      list.splice(index, 1)
    }
    return list
  }

  static removeListItemByVal(list, propertyName, valueToRemove) {
    return list.filter((item) => {
      return item[propertyName] !== valueToRemove
    })
  }

  static removeListItemByIntVal(list, propertyName, valueToRemove) {
    return list.filter((item) => {
      return parseInt(item[propertyName]) !== parseInt(valueToRemove)
    })
  }

  static renameObjectKey(oldObj, oldKey, newKey) {
    const newObj = {}

    Object.keys(oldObj).forEach((key) => {
      const value = oldObj[key]

      if (key === oldKey) {
        newObj[newKey] = value
      } else {
        newObj[key] = value
      }
    })

    return newObj
  }

  static getQueryParams() {
    const params = window.location.search.replace('?', '').split('&')
    let result = {}

    params.forEach(function (param) {
      const parts = param.split('=')
      result[parts[0]] = parts[1]
    })

    return result
  }

  static getQueryParam(key) {
    const params = window.location.search.replace('?', '').split('&')
    let paramVal = ''

    params.forEach(function (param) {
      const parts = param.split('=')

      if (parts[0] === key) {
        paramVal = parts[1]
      }
    })

    return paramVal
  }

  static getStatusBadge(status) {
    if (status === 'locked') return 'danger'

    return 'success'
  }

  static ensureJson(response) {
    if (typeof response.data === 'string') {
      throw new Error(
        'Response does not seem to be valid json. Check the developer console'
      )
    }

    if (!response.headers.hasOwnProperty('content-type')) {
      throw new Error("Response content type is not 'application/json'")
    }

    if (!response.headers['content-type'].includes('application/json')) {
      throw new Error("Response content type is not 'application/json'")
    }
  }

  /**
   * Browsers like Brave does not allow GA, Sentry etc. so we disable
   * 3rd party services. Also disable for development
   */
  static thirdPartyEnabled() {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') return false

    //Check for the brave browser
    return window.navigator.brave !== undefined &&
      window.navigator.brave.isBrave.name === 'isBrave'
      ? false
      : true
  }

  static plural(str) {
    if (str.substr(-1) === 'y') return str.substr(0, str.length - 1) + 'ies'

    return str + 's'
  }

  /**
   * Remove duplicates from array.
   * [1, 1, 2, 3, 3] will become [1, 2, 3]
   * @param {*} arr
   * @returns
   */
  static unique(arr) {
    return arr
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(function (a, b) {
        return a - b
      })
  }

  static decodeBase64(str) {
    return Buffer.from(str, 'base64').toString('ascii')
  }

  static containsText(val, searchText) {
    return String(val).toLowerCase().includes(String(searchText).toLowerCase())
  }

  /**
   * Checks if a required prop has a value. If not prints out more info to help with debugging
   */
  static ensureRequiredProp(props, propName) {
    if (props[propName] === undefined) {
      console.debug('Props for undefined ' + propName + ' error: ', props)
      throw new Error(
        'Parameter ' +
          propName +
          ' cannot be null. See the console window for more info'
      )
    }
  }

  static isEmpty(str) {
    return str === undefined || str === null || str === ''
  }

  /**
   * Creates an array of numbers
   *
   * @param {int} to The end of the range
   * @returns Array
   */
  static numberRange(to) {
    return Array.from({ length: to }, (x, i) => i)
  }

  static chunkArray(items, chunkSize) {
    let chunks = []

    for (let i = 0; i < items.length; i += chunkSize) {
      const chunk = items.slice(i, i + chunkSize)
      chunks.push(chunk)
    }

    return chunks
  }

  static generateUid() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2)
  }

  static generateToken() {
    return Date.now().toString(36) + Utils.generateUid()
  }
}
export default Utils
