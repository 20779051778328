import Utils from '../../services/Utils'
import {
  defineCancelApiObject,
  defaultHeaders,
  GApi,
  LoginErrorStrategy,
} from '../../apis/config/index'

const UserAuthApi = {
  logInWithCredentials: async function (post, cancel = false) {
    const response = await GApi.request({
      url: '/auth/user',
      method: 'POST',
      data: post,
      headers: defaultHeaders(),
      errorStrategy: LoginErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  loginWithSaml: async function (cancel = false) {
    const response = await GApi.request({
      url: 'auth/saml',
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  impersonate: async function (targetUserId, cancel = false) {
    const response = await GApi.request({
      url: '/auth/impersonate/' + targetUserId,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  verifySamlTokenSaml: async function (e, cancel = false) {
    const response = await GApi.request({
      url: 'auth/saml/' + e,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  logout: async function (userId, cancel = false) {
    if (isNaN(userId) || userId === undefined) {
      console.debug('Skipping logout call as userId is not set')
      return
    }

    const response = await GApi.request({
      url: '/auth/user/' + userId,
      method: 'DELETE',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(UserAuthApi)

export default UserAuthApi
