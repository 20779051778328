import axios from 'axios'
import Auth from '../../services/Auth'
import { globalErrorHandler } from './ErrorHandler'

if (process.env.REACT_APP_API === undefined) {
  throw new Error('Could not find REACT_APP_API environment variable')
}

export const GApi = axios.create({
  baseURL: process.env.REACT_APP_API + '/api',
})

// registering the custom error handler to the
// "api" axios instance
GApi.interceptors.response.use(undefined, (error) => {
  return globalErrorHandler(error)
})

export function defineCancelApiObject(apiObject) {
  // an object that will contain a cancellation handler
  // associated to each API property name in the apiObject API object
  const cancelApiObject = {}

  // each property in the apiObject API layer object
  // is associated with a function that defines an API call

  // this loop iterates over each API property name
  Object.getOwnPropertyNames(apiObject).forEach((apiPropertyName) => {
    const cancellationControllerObject = {
      controller: undefined,
    }

    // associating the request cancellation handler with the API property name
    cancelApiObject[apiPropertyName] = {
      handleRequestCancellation: () => {
        // if the controller already exists,
        // canceling the request
        if (cancellationControllerObject.controller) {
          // canceling the request and returning this custom message
          cancellationControllerObject.controller.abort()
        }

        // generating a new controller
        // with the AbortController factory
        cancellationControllerObject.controller = new AbortController()

        return cancellationControllerObject.controller
      },
    }
  })

  return cancelApiObject
}

export function defaultHeaders() {
  const jwt = Auth.getJwtToken()

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'JWT ' + jwt,
  }
}

export function csvDownloadHeaders() {
  const jwt = Auth.getJwtToken()

  return {
    Accept: 'text/csv',
    Authorization: 'JWT ' + jwt,
  }
}

export function excelDownloadHeaders() {
  const jwt = Auth.getJwtToken()

  return {
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'Content-Type': 'blob',
    Authorization: 'JWT ' + jwt,
  }
}

export function fileUploadHeaders() {
  const jwt = Auth.getJwtToken()

  return {
    Accept: 'application/json',
    Authorization: 'JWT ' + jwt,
  }
}

export function fileDownloadHeaders() {
  const jwt = Auth.getJwtToken()

  return {
    'Content-Type': 'blob',
    Authorization: 'JWT ' + jwt,
  }
}
