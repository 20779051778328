import Utils from '../../services/Utils'
import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'

const UserAccessApi = {
  get: async function (groupId, cancel = false) {
    const response = await GApi.request({
      url: '/auth/user-access-group/' + groupId,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  updateAccessGroup: async function (post, cancel = false) {
    const response = await GApi.request({
      url: '/auth/user-access-group',
      method: 'POST',
      headers: defaultHeaders(),
      data: post,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(UserAccessApi)
export default UserAccessApi
