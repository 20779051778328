import Download from '../../services/Download'
import Utils from '../../services/Utils'
import { FormErrorStrategy } from '../config/ErrorHandler'
import {
  GApi,
  defineCancelApiObject,
  defaultHeaders,
  csvDownloadHeaders,
} from '../config/GApi'

const UserApi = {
  getList: async function (cancel = false) {
    const response = await GApi.request({
      url: '/user',
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  exportAsCsv: async function (cancel = false) {
    const response = await GApi.request({
      url: '/user',
      method: 'GET',
      headers: csvDownloadHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Download.csv('users.csv', response.data)
  },
  get: async function (userId, cancel = false) {
    const response = await GApi.request({
      url: '/user/' + userId,
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },

  getMyProfile: async function (cancel = false) {
    const response = await GApi.request({
      url: '/global-reporting/my-profile',
      method: 'GET',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  create: async function (post, cancel = false) {
    const response = await GApi.request({
      url: '/user',
      method: 'POST',
      headers: defaultHeaders(),
      errorStrategy: FormErrorStrategy,
      data: post,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  update: async function (userId, post, cancel = false) {
    const response = await GApi.request({
      url: '/user/' + userId,
      method: 'PUT',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  updateProfile: async function (post, cancel = false) {
    const response = await GApi.request({
      url: '/user/my-profile',
      method: 'POSt',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  deleteUser: async function (userId, cancel = false) {
    const response = await GApi.request({
      url: '/user/' + userId,
      method: 'DELETE',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
  logout: async function (userId, cancel = false) {
    const response = await GApi.request({
      url: '/user/' + userId,
      method: 'DELETE',
      headers: defaultHeaders(),
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)
  },
  changePassword: async function (userId, post, cancel = false) {
    const response = await GApi.request({
      url: '/user/update-password/' + userId,
      method: 'PUT',
      headers: defaultHeaders(),
      data: post,
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(UserApi)

export default UserApi
