import Utils from '../../services/Utils'
import { FormErrorStrategy } from '../config/ErrorHandler'
import { GApi, defineCancelApiObject, defaultHeaders } from '../config/GApi'

const ReportUsersApi = {
  get: async function (reportId, cancel = false) {
    const response = await GApi.request({
      url: '/global-reporting/report-users/' + reportId,
      method: 'GET',
      headers: defaultHeaders(),
      errorStrategy: FormErrorStrategy,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    })

    Utils.ensureJson(response)

    return response.data
  },
}

// defining the cancel API object for ReportUsers
const cancelApiObject = defineCancelApiObject(ReportUsersApi)
export default ReportUsersApi
